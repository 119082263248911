<div class="dialog-data">
  <h3 class="dialog-heading" *ngIf="playlistType === 'youtube'">{{'TRAINING_CONFIGURATION.VIDEO_CHOOSE_HEADING' | translate}}</h3>
  <h3 class="dialog-heading" *ngIf="playlistType === 'custom'">{{'TRAINING_CONFIGURATION.VIDEO_CHOOSE_CUSTOM' | translate}}</h3>
  <div class="playlist-type">
    <mat-radio-group [disabled]="videoUploading" (change)="handleVideoType($event)" [(ngModel)]="playlistType">
      <mat-radio-button value="youtube">YouTube</mat-radio-button>
      <mat-radio-button value="custom">{{'CUSTOM_VIDEO.TYPE_HEADING' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="custom-video" *ngIf="playlistType === 'custom' && !selectedVideo && currentUser?.role !== 'normal'">
    <div class="upload-video-hint" *ngIf="currentUser?.role !== 'normal'">
      <h3>{{'CUSTOM_VIDEO.UPLOAD_VIDEO' | translate}}</h3>
      <span class="material-symbols-outlined info-icon" [ngbTooltip]="uploadVideoHint()">
        info
        </span>
    </div>
    <div class="custom-video-type" *ngIf="!selectedVideo">
      <p>{{'CUSTOM_VIDEO.VIDEO_TYPE' | translate}}</p>
      <mat-radio-group [disabled]="videoUploading" [(ngModel)]="customVideoType">
        <mat-radio-button value="private">{{'CUSTOM_VIDEO.VIDEO_PRIVATE' | translate}}</mat-radio-button>
        <mat-radio-button value="public">{{'CUSTOM_VIDEO.VIDEO_PUBLIC' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div draggable="true" class="drag-area" *ngIf="!selectedVideo">
      <input type="file"
             [multiple]="false"
             accept="video/mp4, video/WebM, video/Ogg"
             (change)="onFileChange($event)"
        />
      <p>
        {{'CUSTOM_VIDEO.DROP_TEXT' | translate}}
        <mat-icon>cloud_upload</mat-icon>
      </p>
    </div>
    <p>{{videoName}}</p>
  </div>
  <div class="youtube-video" *ngIf="playlistType === 'youtube'">
    <div class="add-link-container">
      <div class="field-wrap">
        <mat-form-field appearance="outline">
          <mat-label class="field-label">{{ "TRAINING_CONFIGURATION.YOUTUBE_LINK" | translate }}</mat-label>
          <input matInput (input)="handlePlaylistType($event.target)" [(ngModel)]="linkUrl" #linkInput>
        </mat-form-field>
        <span class="material-symbols-outlined info-icon" [ngbTooltip]="linkTooltip()">
        info
        </span>
      </div>
      <div class="field-wrap">
        <mat-form-field appearance="outline">
          <mat-label class="field-label">{{ "TRAINING_CONFIGURATION.PLAYLIST_NAME" | translate }}</mat-label>
          <input matInput [(ngModel)]="playlistName">
        </mat-form-field>
        <span class="material-symbols-outlined info-icon" [ngbTooltip]="nameTooltip()">
        info
        </span>
      </div>
    </div>
    <div class="add-playlist">
      <button  [hidden]="youtubeLinkType === 'videoId'" mat-raised-button (click)="getPlaylist()">{{ "TRAINING_CONFIGURATION.GET_PLAYLIST" | translate }}</button>
    </div>
    <div class="open-youtube-container">
      <button class="youtube-button" mat-raised-button (click)="youtubeNavigate()">
        {{ "TRAINING_CONFIGURATION.OPEN_TEXT" | translate }}
        <img class="youtube-img" src="assets/media/svg/logos/youtube-3.svg" alt="youtube">
      </button>
    </div>

    <div class="history" *ngIf="playlistHistoryArray.length">
      <h2>{{ "TRAINING_CONFIGURATION.PLAYLIST_HISTORY" | translate }}</h2>
      <div class="playlist-item" *ngFor="let video of playlistHistoryArray">
        <mat-radio-group (change)="getChoosenPlaylist($event, true)">
          <mat-radio-button [checked]="choosenPlaylist && choosenPlaylist.url === video.url" [value]="video">{{video.name}}</mat-radio-button>
        </mat-radio-group>
        <button class="delete-button" (click)="deleteYoutubeVideoSuperUser(video)" *ngIf="currentUser?.role === 'super user' && video.isDefault">
          <span class="material-symbols-outlined">
            delete
          </span>
        </button>
        <button class="delete-button" (click)="deleteYoutubeVideoFromHistory(video)" *ngIf="currentUser?.role !== 'super user' && !video.isDefault">
          <span class="material-symbols-outlined">
            delete
          </span>
        </button>
      </div>
    </div>
  </div>
  <h3 *ngIf="playlistType === 'custom'" class="select-from-exiting-text">{{'CUSTOM_VIDEO.SELECT_FROM_EXITING' | translate}}</h3>
  <div class="videos-list" *ngIf="playlistType === 'custom'">
    <h3 *ngIf="currentUser?.role !== 'normal'">{{'CUSTOM_VIDEO.YOUR_VIDEOS' | translate}} {{userVideos.length}}</h3>
    <button color="warn" *ngIf="selectedVideo" mat-button (click)="clearSelectedVideo()">{{ "TRAINING_CONFIGURATION.CLEAR_SELECTED" | translate }}</button>
    <mat-radio-group class="video-group" [disabled]="videoUploading" [(ngModel)]="selectedVideo" (change)="setVideoFromList($event)">
      <mat-radio-button [ngClass]="video.selected ? 'selected-video ' : ''" *ngFor="let video of userVideos" [value]="video">
        {{video?.name | titlecase | slice:0: limitOfCharacters(video.name.length) }}
        <video (loadeddata)="getVideoDuration($event, video)" controls class="video-preview" [src]="video.videoPreview"></video>
        <span class="video-duration">{{video?.duration}}</span>
        <mat-icon (click)="deleteVideo(video, 'private')" color="warn">delete</mat-icon>
      </mat-radio-button>
    </mat-radio-group>

    <h3>{{'CUSTOM_VIDEO.PUBLIC_VIDEOS' | translate}} {{publicVideos.length}}</h3>
    <mat-radio-group class="video-group" [disabled]="videoUploading" [(ngModel)]="selectedVideo" (change)="setVideoFromList($event)">
      <mat-radio-button [ngClass]="video.selected ? 'selected-video ' : ''" *ngFor="let video of publicVideos" [value]="video">
        {{deleteVideoFormat(video?.name) | titlecase | slice:0: limitOfCharacters(video.name.length) }}
        <video (loadeddata)="getVideoDuration($event, video)" controls class="video-preview" [src]="video.videoPreview"></video>
        <span class="video-duration">{{video?.duration}}</span>
        <span class="video-owner" *ngIf="video.ownerName | titlecase">{{'CUSTOM_VIDEO.UPLOADED_TEXT' | translate}} {{video?.ownerName}}</span>
        <mat-icon *ngIf="video.metadata === currentUser.id" (click)="deleteVideo(video, 'public')" color="warn">delete</mat-icon>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div  class="actions-buttons">
    <button *ngIf="youtubeLinkType === 'playlist'" mat-raised-button [disabled]="playlistError || !playlistName || videoUploading" (click)="confirm()">{{ "TRAINING_CONFIGURATION.SAVE_DIALOG" | translate }}</button>
    <button *ngIf="youtubeLinkType === 'videoId'" mat-raised-button [disabled]="videoUploading" (click)="confirm()">{{ "TRAINING_CONFIGURATION.SAVE_DIALOG" | translate }}</button>
    <button mat-raised-button [disabled]="videoUploading" color="warn" class="confirm-button" (click)="close()">{{ "TRAINING_CONFIGURATION.CLOSE_DIALOG" | translate }}</button>
  </div>
</div>

<div class="progress-upload" *ngIf="videoUploading && playlistType === 'custom'">
  <p>{{'CUSTOM_VIDEO.VIDEO_UPLOADING' | translate}}</p>
  <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
</div>
