// English
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'New',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      PROFILE: 'Profile',
      ARCHIVE: 'Archive',
      LIVEVIEW: 'Live View',
      MY_ARCHIVE: 'My Archive',
      OTHER_USER: 'Other User',
      LIVE_VIEW: 'Live View',
      AMPLITUDE_OVERVIEW: 'Amplitude Overview',
      RAW_CHART: 'Raw Signal',
      RATIO_CHART: 'Ratio Overview',
      CUSTOM_LIVE_VIEW: 'Custom Live View',
      CHARTS: 'Charts',
      TRAINING_CONFIGURATION : 'Training Configuration',
      TRAINING:'Training',
      TRAINING_SCREEN:'Training Screen',
      REMOTE_TRAINING:'Remote Training',
      ANALYSIS: 'Analysis',
      ANALYSIS_SCREEN: 'Analysis Screen',
      RECORDINGS: 'Select Recording(s)',
      TEST: 'Test',
      TEST_SCREEN: 'Test Screen',
      TEST_CONFIGURATION: 'Test Configuration',
      BLUETOOTH: 'Bluetooth®',
      STATISTIC: 'Statistic',
      DASHBOARD: 'Dashboard',
      UPDATE_TEXT: 'Training updated'
    },
    AUTH: {
      ASIDE: {
        WELCOME_TEXT: 'Welcome to eSense EEGenius \n Web App',
        DATA_PRIVACY: 'Data Privacy',
        ABOUT: 'About',
        CONTACT: 'Contact',
        LEGAL: 'Legal',
        PRIVACY: 'Privacy'
      },
      GENERAL: {
        SIGN_OUT: 'Sign out',
        OR: 'Or',
        SUBMIT_BUTTON: 'CREATE FREE ACCOUNT',
        CREATE_ACCOUNT: 'Create free account',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign up',
        FORGOT_BUTTON: 'Forgot your password?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        RESET_PASSWORD: 'Reset your password',
        ACCOUNT_FEATURES: 'Explaining the account features',
        NEW_DASHBOARD: 'The new eSense Dashboard'
      },
      LOGIN: {
        TITLE: 'Login to your account',
        LOGIN_BUTTON: 'LOGIN',
        LOGIN_GOOGLE: 'REGISTER & LOGIN WITH GOOGLE+',
        LOGIN_APPLE: 'Continue with Apple',
        BUTTON: 'Sign in',
        APPLE_LINK_TEXT: 'The app works on iPads. Please download our iOS "eSense EEGenius" app.'
      },
      FORGOT: {
        TITLE: 'Forgotten your password?',
        DESC: 'Enter your E-Mail to reset your password.',
        SUCCESS: 'Your accounts password has been successfully reset.',
        FAILURE: 'Sorry, try again.'
      },
      REGISTER: {
        TITLE: 'Sign up',
        DESC: 'Enter your details to create your account:',
        SUCCESS: 'Your account has been successfuly registered.',
        BACK_TO_LOGIN: 'Back to login',
        BACK: 'Back',
        REGISTRATION: 'Registration',
        AGREE: 'I agree the',
        TERMS: 'terms & conditions',
        CANCEL: 'Cancel',
        YOUR_DETAILS: 'My details',
        YOUR_SUPERVISOR: 'My Supervisor',
        CHOOSE_SUPERVISOR: 'Choose Supervisor',
        I_IM_SUPERVISOR: 'I`m a Supervisor myself, here is my code',
        AGE: 'Age',
        SUPERVISOR_FROM_LIST: 'Choose Supervisor from the list',
        SUPERVISED_TEXT: 'I am supervised by Mindfield',
        SUPERVISOR_HINT: 'The supervisor will receive an E-Mail and needs to accept this registration!',
        WRONG_SUPERVISOR_CODE: 'Wrong code'
      },
      INPUT: {
        EMAIL: 'E-Mail',
        FULLNAME: 'Your Name',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions is required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'There is no user record corresponding to this E-Mail. The user may have been deleted',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        USER_NOT_FOUND: 'User not found',
        EMAIL_INVALID: 'E-Mail address is invalid',
        PASSWORD_INVALID: 'Invalid password'
      }
    },
    PROFILE: {
      PROFILE: {
        PROFILE: 'Profile',
        PERSONAL_INFO: 'Personal Info',
        ALLOWED_SUPERVISORS: 'My Supervisors',
        ACCESS_DATA: '(With access to my data)',
        ID: 'ID',
        USER_ROLE: 'User Role',
        NAME: 'Name',
        GENDER: 'Gender',
        MALE: 'Male',
        FEMALE: 'Female',
        OTHER: 'Other',
        AGE: 'Age',
        EMAIL: 'E-Mail',
        UPDATE: 'Save changes',
        STATS: 'Stats',
        SESSIONS: 'Sessions',
        DAYS_OF_USAGE: 'Days of usage',
        CREATED_AT: 'Created at',
        GROUPS: 'Groups',
        PLANS: 'Plans',
        SUPERVISOR_DESC: 'You are a supervisor, this includes all features of the premium plan.',
        ABOUT_BASIC: 'Store all your sessions local and online in the cloud. Access your sessions from all your devices.',
        ABOUT_PREMIUM: 'Store all your sessions + procedures local and online in the cloud. Access your sessions + procedures from all your devices. Share your sessions with other users. Get access to online dashboard and real time streaming.',
        PRICE_RANGE: 'Price range:',
        PREMIUM_PLAN: 'Premium plan',
        BASIC_PLAN: 'Basic plan',
        SUBSCRIBED_AT: 'Subscribed at',
        ENDS_AT: 'Ends at',
        LANGUAGE: 'Preferred language for communication',
        LANGUAGE_SELECTOR_EN: 'English',
        LANGUAGE_SELECTOR_DE: 'German',
        SELECT_IMAGE: 'Select image',
        PAGES_COACHMARKS: 'Show help tooltips',
        DELETE_ACCOUNT: 'Delete account',
        VIDEO_CALL: 'Direct video call',
        GROUP_CALL: 'Group meeting',
        STATUS_ONLINE: 'Online',
        STATUS_OFFILE: 'Away',
        STATUS_UNKNOWN: 'Unknown',
        LAST_SEEN: 'Last seen:',
        USER_MANAGEMENT: 'User management',
        COPY_LINK: 'This is your link for your new clients to register, with you as supervisor preselected:',
        COPY_LINK_MESSAGE: 'Link created successfully',
        SUPERVISORS_ARROW_TEXT: 'Manage',
        SURVEY_DATA: 'Goals information',
        NO_GOALS: 'The user has not specified the goals yet.',
        INVITE_LINK: 'Or invite user via E-Mail:',
        INVITE_LINK_CONFIRM: 'Invite link sent to the user',
        INVITE_LINK_ERROR: 'This user is not exists',
        APPROVAL_LIST: 'Users waiting for approval',
        COPY_TO_CLIPBOARD: 'Copy link to clipboard',
        SUPERVISOR_PENDING: 'Pending accept',
        PROFILE_UPDATED: 'Profile updated',
        SURVEY_HEADLINE: 'Survey (Optional for supervisors)',
        SUPERVISORS_TREE: 'Supervisors & Users',
        SUPERVISORS_ACTIVITY: 'Supervisors activity',
        ADD_SUPERVISOR: 'Add another supervisor',
        SUPERVISOR_ID: 'Enter supervisor ID',
        SUPERVISOR_ERROR: 'This supervisor is not exists',
        SELF_ID_ERROR: 'You cannot add yourself to this list',
        SUPERVISOR_ADDED: 'The request has been sent to a supervisor',
        ADD_TEXT: 'Add',
        SUPERVISOR_EXISTS: 'Error, this supervisor already in list'
      },
      NEWS: {
        NEWS: 'News',
        CREATE_NEWS: 'Create News',
        TITLE: 'Title',
        CANCEL: 'Cancel',
        CREATE: 'Create',
        CONTENT: 'Content'
      },
      BILLING: {
        BILLING: 'Billing',
      },
      EMAIL : {
        EMAIL: 'E-Mail'
      }
    },
    ARCHIVE: {
      RECORDINGS: {
        RECORDINGS: 'Recordings',
        SHARED_RECORDS: 'Shared Records',
        RECORDING_NAME: 'RECORDING NAME',
        DATE: 'DATE (HH:MM:SS)',
        TIME: 'TIME (HH:MM:SS)',
        TIME_INCREASE: 'TIME INCREASE (HH:MM:SS)',
        TIME_STEADY: 'TIME STEADY (HH:MM:SS)',
        TIME_DECREASE: 'TIME DECREASE (HH:MM:SS)',
        MAXIMUM: 'MAXIMUM',
        MINIMUM: 'MINIMUM',
        AVERAGE_BREATH_PER_MINUTE: 'AVERAGE BREATH PER MINUTE',
        DIFFERENCE: 'DIFFERNCE MIN/MAX',
        TOTAL_SCR: 'TOTAL SCR',
        SCR_OF_SESSION: '% OF SCR OF SESSION',
        ELECTRODE_FIXATION: 'ELECTRODE FIXATION',
        STRESS_INDEX: 'STRESS INDEX',
        AVERAGE_HR: 'AVERAGE HR',
        AVERAGE_RR: 'AVERAGE RR',
        CORRECTED_HR_VALUES: 'CORRECTED HR VALUES',
        CORRECTED_HR_VALUES_IN_PERCENTAGE: 'CORRECTED HR VALUES IN %',
        COHERENCE: 'COHERENCE',
        BREATH_PER_MINUTE: 'BREATH PER MINUTE',
        AVERAGE_OF_SESSION: 'AVERAGE OF SESSION',
        TEMPERATURE_CURRENT: 'CURRENT VALUE',
        MAXIMUM_30S: 'DEPTH OF BREATH',
        TEMPERATURE_MAXIMUM: 'MAXIMUM',
        TEMPERATURE_MINIMUM: 'MINIMUM',
        TEMPERATURE_DIFFERENCE: 'DIFFERENCE MIN/MAX',
        AVERAGE_VALUE: 'AVERAGE OF SESSION',
        INCREASE_TIME: 'TIME INCREASE (HH:MM:SS)',
        DECREASE_TIME: 'TIME DECREASE (HH:MM:SS)',
        STEADY_TIME: 'TIME STEADY (HH:MM:SS)',
        INCREASE_TIME_HRV: 'Increase of HRV amplitude',
        STEADY_TIME_HRV: 'Steady of HRV amplitude',
        DECREASE_TIME_HRV: 'Decrease of HRV amplitude',
        SCR_LOW: '0-5 SCR/MIN (HH:MM:SS)',
        SCR_AVG: '6-9 SCR/MIN (HH:MM:SS)',
        SCR_HIGH: '10-15 SCR/MIN (HH:MM:SS)',
        SCR_MAX: '>16 SCR/MIN (HH:MM:SS)',
        SCR_MIN_VALUE: 'SCR/MIN',
        SCR_COVERAGE: '% of SCR OF SESSION',
        SCR_TOTAL: 'TOTAL SCR',
        SCR: 'SCR',
        CURRENT_CALCULATED_RA: 'CURRENT CALCULATED RA',
        AVERAGE_REGULARITY: 'AVERAGE REGULARITY',
        HOW_DO_YOU_FEEL: 'HOW DO YOU FEEL?',
        HEARTS: 'Hearts',
        NO_RECORDINGS: 'No Recordings',
        SCORE: 'SCORE',
        USER_ID: 'User ID or E-Mail',
        DEPTH_OF_BREATH: 'DEPTH OF BREATH'
      },
      ANALYZE: {
        ANALYZE: 'Analysis',
        NO_ANALYZE: 'No Analysis',
        NO_CHART_SELECTED: 'No chart selected',
        CHOOSE_A_DATE: 'Choose a date'
      },
      SHARED_RECORDS: {
        SHARED_RECORDS: 'Shared Records',
        NO_SHARED_RECORDS: 'No Shared Records'
      }
    },
    OTHER_USER: {
      USER_ID: 'User ID or E-Mail',
      SEARCH_FOR_IDEMAIL: 'Search for ID/E-Mail'
    },
    LIVE_VIEW: {
      NO_LIVE_VIEW: 'No Live View',
      TIME: 'Time (HH:MM:SS)',
      MAXIMUM: 'Maximum',
      MINIMUM: 'Minimum',
      DIFFERENCE: 'Differnce Min/Max',
      COHERENCE: 'Coherence',
      BREATH_PER_MINUTE: 'Breaths/Min',
      BREATHING: 'Breathing',
      AVERAGE_OF_SESSION: 'Average of Session',
      CURRENT_VALUE: 'Current Value',
      REGULARITY: 'Regularity',
      CURRENT_CALCULATED_RA: 'Current Calculated RA',
    },
    CUSTOM_CHARTS: {
      USER_ID_OR_EMAIL: 'User ID or E-Mail',
      ADD_CUSTOM_CHART: 'Add Custom Chart',
      TYPE: 'Type',
      SAVE_CUSTOM_PATTERN: 'Save Current Pattern'
    },
    USER_PROFILE: {
      USER_PROFILE: 'User Profile',
      USER_ROLE: 'User Role',
    },
    GENIUS_SETTINGS: {
      CONNECT_TEXT: 'Connect',
      DISCONNECT_TEXT: 'Disconnect',
      HOW_TO_PLACE: 'How to place the electrodes',
      CHANNEL_TEXT: 'Impedance:',
      NEGATIVE_IMPEDANCE: 'You got negative impedance values. This happens under very rare conditions if you have used too much NaCl and a salt bridge built up in between the front- and backside of the elctrode. Please remove the electrode, dry any NaCl that is not only in the white felt pad and put the electrode back on. If the negative impedance value (often -4.5 which signals an overload of the amplifier) is not gone, replace the electrode with another new one. Let it dry completly, try to reduce the amount of NaCl next time. You may need to do this procedure with all three electrodes you are using to find the one that is causing the negative impedances.'
    },
    RECORD_ACTIONS: {
      ACTIVITY: 'Press record to save your activity:',
      PRESET_LENGTH: 'Preset length (stops automatically)',
      MIN: 'min'
    },
    INFO_POPUP: {
      HOW_TO_USE : 'Please place the electrodes like this:',
      LEFT: 'Left',
      CENTER: 'Center',
      RIGHT: 'Right'
    },
    CONFIRM_DELETE_CONFIGURATION: {
      SURE_TEXT: 'Are you sure want to delete?',
      CONFIRM: 'Confirm',
      CLOSE: 'Abort'
    },
    TEST_CONFIGURATION: {
      HEADING: 'Test Configuration',
      PART_TEXT: 'Part',
      SAVED_TEMPLATES : 'Choose a template or create new one',
      CREATE_NEW: 'Create new',
      LENGTH: 'Length',
      ENABLED: 'Enabled',
      OPTION: 'Text',
      SITUATION_NAME: 'Test name',
      SAVE_TEXT: 'Save',
      PLAY_AUDIO: 'Sound and Tone',
      CHECK_AUDIO: 'Test tone',
      START_PROCEDURE: 'Start Test',
      SELECT_IMAGE: 'Image',
      SELECT_TONE: 'Select music',
      HINT_TEXT: 'When checkbox is checked, at the end of this part a tone is played.',
      AUDIO_ERROR: 'Supported audio formats : mp3, waw, oog',
      IMAGE_ERROR: 'Unsupported image format',
      CUSTOM_TEMPLATES: 'Custom templates',
      ASSIGN_TEXT: 'Assign this configuration to one or more users by ticking the box beside one or more users. The users will then be informed by e-mail. (If you uncheck a box beside a user, this training is withdrawn from the user and is no longer available for selection. The user will also be informed of this by e-mail). Please save or update the configuration then.',
      TEMPLATE_USER_ID: 'User ID or E-Mail',
      UPDATE_TRAINING_SUCCESS: 'Test updated',
      ALREADY_EXISTS: 'This test already exists, please choose another name',
      TEMPLATE_ADDED: 'Test template created successfully',
      ALL_TEMPLATES: 'All templates',
      NO_TRAININGS_FOR_USER: 'There are no templates for that user',
      ASSIGNED_USERS_FILTER: 'Filter by test, that are assigend to a user:'
    },
    TEST_CONFIG_DIALOG: {
      HEADING: 'Text template list',
      ADD_TEXT: 'Add template',
      CLOSE: 'Close'
    },
    TRAINING_CONFIGURATION: {
      HEADING: 'Training configuration',
      TEMPLATES_TEXT: 'Choose a template or create new one',
      SELECT_TEXT: 'Enabled',
      TOPIC: 'Subtitle',
      LENGTH: 'Length',
      MIN: 'min',
      PROTOCOL: 'Protocol',
      FREQUENCY_ONE: '1st Frequency band',
      FREQUENCY_TWO: '2nd Frequency band',
      THRESHOLD_TYPE: 'Threshold Type',
      YOUTUBE_PLAYLIST: 'Video',
      NOTE: 'Note',
      CLOSE_DIALOG: 'Abort',
      SAVE_DIALOG: 'Save',
      YOUTUBE_LINK: 'Playlist URL or Video URL',
      PLAYLIST_NAME: 'Playlist or Video Name',
      PLAYLIST_HISTORY: 'Or choose from exiting Playlist or Video from History',
      YOUTUBE_INVALID_LINK: 'Invalid Playlist URL',
      GET_PLAYLIST: 'Fetch URL',
      PLAYLIST_ADDED: 'Playlist added',
      TEMPLATE_NAME: 'Training name',
      SAVE_BUTTON: 'Save',
      DELETE_TEXT: 'Are you sure want to delete this template?',
      CONFIRM_DELETE: 'Confirm',
      CLOSE: 'Abort',
      INVALID_USER: 'Invalid User ID or E-Mail',
      ASSIGN_TEXT: 'Assign a user to this training and save it!',
      TEMPLATE_USER_ID: 'User ID or E-Mail',
      ASSIGNED_USERS_TEXT: 'Assigned users',
      UPDATE_USERS_BUTTON: 'Update',
	    UPDATE_TEXT: 'Training updated',
      ALREADY_EXISTS: 'This training already exists, please choose another name',
      TEMPLATE_ADDED: 'Training template created successfully',
      TEMPLATE_CATEGORY: 'Survey main category',
	    TEMPLATE_SUBCATEGORY: 'Survey sub category',
      CLEAR_SELECTED: 'Clear selected',
      ASSIGNED_USERS_FILTER: 'Filter by trainings, that are assigend to a user',
      CUSTOM_TEXT: 'Subtitle text(optional)',
      VISIBILITY: 'Visibility',
      CHART_VISIBILITY: 'Line',
      BAR_VISIBILITY: 'Bar',
      VIDEO_VISIBILITY: 'Video',
      VIDEO_SPEED: 'Speed',
      SELECT_ERROR: 'You need at least one active part',
      LINK_TOOLTIP: 'Here you can insert an URL to a YouTube video playlist or a single YouTube video.',
      NAME_TOOLTIP: 'Give a name to your YouTube playlist or YouTube video, after confirmation, this playlist or video will be saved in the history, which you can use again in the future.',
      OPEN_TEXT: 'Open YouTube in a new tab',
      VIDEO_CHOOSE_HEADING: 'Add new Playlist or Video form YouTube or Custom Video Files:',
      VIDEO_CHOOSE_CUSTOM: 'Add a new Video:'
    },
    TRAINING_ICONS : {
      FOCUS: 'Focus',
      CONCENTRATION: 'Concentration',
      CALM: 'Calm',
      RELAXATION: 'Relaxation',
      MEMORY: 'Memory',
      THINKING: 'Thinking',
      LEARNING: 'Learning',
      SPEED_UP: 'Speed Up',
      SLOW_DOWN: 'Slow down',
      TUNING: 'Tuning'
    },
    TRAINER_TEMPLATE: {
      START: 'Start',
      STOP: 'Stop',
      NOTE_TEXT: 'The training is optimised for your form of the day.',
      PAUSED: 'Paused',
      RESUME: 'Resume',
      TRAINING_PART: 'Training part:',
      TOTAL_TIME: 'Total time:',
      PAUSE_BY_ERRORS: 'We noticed that the connection with the sensor is unstable, this has caused a pause, please improve the connection.'
    },
    TEST_SCREEN_TRAINING: {
      START: 'Start',
      STOP: 'Stop',
      PROCEDURE_PREVIEW: 'Test preview',
      PART_TEXT: 'Part',
      CHOOSE_TEST: 'Choose a test',
      HEADING: 'Choose and run a test',
      CONNECT_SENSOR: 'Please connect your eSense EEGenius!'
    },
    DEFAULT_TEXT_OPTION: {
      CLOSE_EYES: 'Close your eyes until a tone sounds',
      OPEN_EYES: 'Please keep your eyes open',
      ONE_COUNTDOWN: 'Please count backwards from 1000 in steps of 1',
      SEVEN_COUNTDOWN: 'Please count backwards from 1000 in steps of 7',
      THREE_COUNTDOWN: 'Please count backwards from 1000 in steps of 3',
      BE_QUIET: 'Please be relaxed, have your eyes open',
      READ_TEXT: 'Read a text',
      LISTEN_MUSIC: 'Listen to the music'
    },
    SIMULATOR_MODE: {
      SIMULATOR_ON: 'Simulator mode on',
      SIMULATOR_OFF: 'Simulator mode off'
    },
    CHART_OPTIONS: {
      CHANNEL: 'Channel',
      CHANNEL_ONE: 'Channel 1',
      CHANNEL_TWO: 'Channel 2',
      Y_VALUE: 'Y-Axis value (µV)',
      X_VALUE: 'X-Axis value (s)',
      AVERAGE_PRESETS: 'Average presets (s)',
      ZOOM_STEP: 'Zoom step (µV)',
      AVERAGE_OSCILLOSCOPE: 'Average presets (s)',
      AVERAGE_BAR: 'Average presets (s) Bar',
      THRESHOLD_TYPE: 'Threshold Type',
      THRESHOLD_VALUE: 'Threshold Value',
      THRESHOLD_TYPE_INHIBIT: 'Inhibit',
      THRESHOLD_TYPE_REWARD: 'Reward',
      TRAINING_TYPE: 'Training type',
      HIDE_OPTIONS: 'Hide options for user',
      SHARING_ON: 'Yes',
      SHARING_OFF: 'No',
      ADD_TRAINER: 'Add trainer ( ID or E-Mail )',
      ADD_TRAINER_BUTTON: 'Add',
      ADD_TRAINER_ERROR: 'User ID or E-Mail is invalid',
      SIGNAL_DISTANCE: 'Threshold to signal distance in % :',
      SUCCESS_RATE: 'Success rate in %/min :',
      SHARE_USERS_TEXT: 'Remote Training',
      AUTO_THRESHOLD: 'Auto Threshold',
      TRAINER_TEMPLATES: 'Select your training',
      CONNECT_SENSOR: 'Please connect your eSense EEGenius!',
      ANALYSIS_TYPE: 'Analysis type',
      NO_DATA: 'No data',
      CHOOSE_ONE: 'Choose one option',
      TRAINING_DIFFICULTY: 'Training difficulty',
      EASY_TRAINING: 'Easy (80-90%)',
      NORMAL_TRAINING: 'Normal (70-80%)',
      HARD_TRAINING: 'Challanging (60-70%)',
      REMOTE_TRAINING: 'Remote Training',
      TRANSMISSION_SPEED: 'Remote Training transmission speed'
    },
    YOUTUBE_VIDEO : {
      TRAINER_TEXT: 'Your trainer:',
      ARTEFACT_TEXT: 'Artefakt signal threshold',
      OPEN_WINDOW: 'Open in Window',
      VOLUME: 'Volume',
      MUTE_VIDEO: 'Mute Video',
      UNMUTE_VIDEO: 'Unmute Video',
      DISCONNECT_TEXT: 'Disconnect',
      PLAYBACK_RATE: 'Playback rate',
      CHANGE_VIDEO: 'Change video',
      FEEDBACK_TYPE: 'Feedback type',
      FEEDBACK_STOP: 'Start/Stop',
      FEEDBACK_SLOW:'Normal/Slow'
    },
    CUSTOM_TRAINING_SCREEN: {
      ENTER_USER_ID: 'Please enter User ID or E-Mail',
      USER_INVALID: 'User ID or E-Mail is invalid',
      CHART_OPTIONS: 'Chart options',
      SELECTED_USERS: 'Selected users',
      SET_OPTIONS: 'Set Options',
      SHOW_HIDE: 'Show / Hide',
      SUBMIT: 'Submit',
      YOUTUBE_PLAYLIST: 'Youtube Playlist URL',
      PLAYLIST_LINK_ERROR: 'Invalid playlist link'
    },
    CUSTOM_TRAINING_CHART: {
      USER: 'User:',
      OPTIONS: 'Options:',
      SENSOR_VALUE: 'Sensor Value:',
      STEP: 'Step',
      VALUES: 'Values:',
      VIDEO_PLAY_TEXT: 'Video is playing:',
      ATREFAKT_SIGNAL: 'Artefact signal:',
      VIDEO_PLAY: 'Yes',
      VIDEO_NOT_PLAY: 'No',
      AUTO_THRESHOLD: 'Auto threshold',
      AUTO_THRESHOLD_ON: 'On',
      AUTO_THRESHOLD_OFF: 'Off',
    },
    SELECT_RECORDING: {
      ARCHIVE: 'Archive',
      SELECT_USERS: 'Select Users',
      SUPERVISORS: 'Users list',
      NO: 'No.',
      CURRENT_USER: 'My Archive',
      SELECT: 'Select',
      DATE: 'Date',
      LENGTH: 'Length',
      TYPE: 'Name',
      BAD_SIGNAL: 'Bad Signal (%)',
      NOTE: 'Note',
      DOWNLOAD: 'Download',
      DELETE: 'Delete',
      TRAININGS_TAB: 'Trainings',
      TESTS_TAB: 'Tests',
      OTHERS_TAB: 'Other',
      TO_ANALYSIS: 'Go to analysis',
      DELETE_SELECTED: 'Delete selected records',
      CONFIRM_TEXT_FIRST: 'Are you sure want to delete?',
      CONFIRM_TEXT_SECOND: 'records',
      SEARCH_TEXT: 'Search',
      NO_RESULTS: 'No results',
      TABLE_STYLE_NORMAL: 'Normal view',
      TABLE_STYLE_EXPANDED: 'Aggregated by training',
      EXPAND_MORE: 'Expand...',
      RECORDS_COUNT: 'Records count:',
      BACK_TO_TREE: 'Back to tree',
      HIDE_INCOMPLETE: 'Hide incomplete sessions',
      RATING_TEXT: 'Rating:',
      USER_NAME: 'Name',
      USER_ID: 'ID',
      USER_EMAIL: 'E-mail',
      USER_LAST_LOGIN: 'Last login',
      USER_SESSIONS: 'Number of Sessions',
      USER_TOTAL_SECONDS: 'Total Seconds of Training',
      USER_GOALS: 'Goals',
      NEW_DATA: '(new data)',
      SELECT_TEXT: 'Select',
      FILTER_ALL: 'All',
      FILTER_SUPERVISORS: 'Supervisors',
      FILTER_NORMAL: 'Normal users',
      COMMENT: 'Supervisor comment',
      USER_NOTE: `User's note`,
      SENSOR_ERRORS: 'Errors:',
      SENSOR_LOST_PACKETS: 'Lost packets:',
      SENSOR_INTERRUPTIONS: 'Signal interruptions:',
      LOAD_MORE: 'Load more',
      INCOMPLETE: 'incomplete'
    },
    EDIT_NOTE: {
      SAVE: 'Save',
      CLOSE: 'Close',
      NOTE: 'Note',
      INCOMPLETE: 'INCOMPLETE'
    },
    DELETE_RECORDING: {
      SURE_TEXT: 'Are you sure want to delete this record?',
      CONFIRM: 'Delete',
      CLOSE: 'Close'
    },
    ANALYSIS_SCREEN: {
      EXPORT_PDF: 'Export current view as PDF',
      SET: 'Set',
      EMPTY_DATA: 'Please select at least one recording first in "Select Recording(s)" section.',
      TREND_TEXT: 'Trend:',
      MINUTES_TEXT: 'Minutes:',
      FREQUENCY_ONE: '1st Frequency band',
      FREQUENCY_TWO: '2nd Frequency band',
      TRAINING_TEXT: 'Training:',
      STRONGEST_TREND: 'Strongest trend',
      NORMAL_TREND: 'Normal trend',
      NO_TREND: 'No trend',
      TREND_COLUMN: 'Column',
      TREND_ROW: 'Row',
      TREND_STYLE: 'Trends view',
      TO_ARCHIVE: 'To archive',
      TOGGLE_TIMING_LINES: 'Hide rating timing ranges'
    },
    CHARTS_NAMING: {
      AMPLITUDE: 'Amplitude Overview',
      RAW_SIGNAL: 'Raw Signal',
      RATIO: 'Ratio Amplitude Overview',
      RATIO_ANALYSIS: 'Ratio',
      AMPLITUDE_ANALYSIS: 'Amplitude',
      TRAINING_SCREEN: 'Training screen',
      BOXPLOT: 'Boxplot (single session)',
      BOXPLOT_MIXED: 'Boxplot (multiple sessions)',
      BOXPLOT_WITHIN: 'Boxplot of within a session analysis',
      ANALYSIS_SCREEN: 'Analysis screen',
      PARTS_BOX_CHART: 'Boxplot of a test'
    },
    CREDITS: {
      CREDITS_TEXT: 'Credits'
    },
    DASHBOARD: {
      REWARD_NEED: 'You need',
      TO_REACH: 'points to reach',
      TOTAL_TIME: 'Total time',
      USER_LEVEL: 'Level:',
      ACTIVITY_FEED: 'Activity feed',
      EMAIL_NOTIFICATION: 'E-Mail Notification',
      SELECT_DASHBOARD: 'Select other user dashboard',
      YOUR_TEXT: 'My Dashboard',
      NEW_TRAINING: 'New training from',
      TOTAL_TRAININGS: 'Total trainings ',
      DAY_TRAININGS: 'Current Day Trainings:',
      WEEK_TRAININGS: 'Current Week Trainings:',
      MONTH_TRAININGS: 'Current Month Trainings:',
      DAY_MINUTES: 'Todays Training Minutes:',
      WEEK_MINUTES: 'Weekly Training Minutes:',
      MONTH_MINUTES: 'Monthly Training Minutes:',
      MOST_TRAINING: 'Most training sessions in a week:',
      MOST_TRAINING_TRAININGS: '',
      MOST_TRAINING_RANGE: 'Range:',
      INTERVALS_SESSIONS: 'Shortest and longest time interval in between two training sessions:',
      INTERVALS_HOURS: 'Hours',
      INTERVALS_NAME: 'Name:',
      INTERVALS_STREAK: 'Your best training streak:',
      INTERVALS_MAX_DIFFERENCE: 'Largest difference in time between two training sessions:',
      INTERVALS_MIN_DIFFERENCE: 'Smallest difference:',
      NO: 'No.'
    },
    TROPHY_NAMES: {
      FIRST: 'Junior Brain',
      SECOND: 'Trained Brain',
      THIRD: 'Advanced Brain',
      FOURTH: 'Expert Brain',
      FIVE: 'Super Brain',
      SIX: 'Hyper Brain',
      SEVEN: 'Einstein'
    },
    BADGES_NAMES: {
      NONE: 'None',
      FIRST: 'Newbie',
      SECOND: 'Beginner',
      THIRD: 'Junior',
      FOURTH: 'Experienced',
      FIVE: 'Advanced',
      SIX: 'Expert',
      SEVEN: 'Professional',
      EIGHT: 'World Class',
      NINE: 'Super Brain',
    },
    BILLING: {
      ERROR_TEXT: 'Please fill in all required fields',
      PAGE_TEXT: 'Page',
      DATE_TEXT: 'Date',
      TIME_TEXT: 'Time',
      TODAY_FILTER: 'Today',
      WEEK_FILTER: 'This Week',
      MONTH_FILTER: 'This month',
      LAST_MONTH_FILTER: 'Last month',
      THIS_YEAR_FILTER: 'This year',
      SELECT_USERS_LIST: 'Users list',
      MY_ARCHIVE: 'My statistics',
      USER_DATA: 'Get user data',
      GET_DATA: 'Get data',
      DATA_ERROR: 'User ID or E-Mail is invalid',
      TOTAL_AMOUNT: 'Total time of choosen period:',
      USER_DETAILS: 'User details',
      COMPANY: 'Company',
      FIRST_NAME: 'First name',
      SURNAME: 'Surname',
      STREET: 'Street',
      POST_CODE: 'Post Code',
      CITY: 'City',
      STATE: 'State',
      COUNTRY: 'Country',
      E_MAIL: 'E-Mail',
      SAVE_CHANGES: 'Save changes',
      VALID: 'Are all fields filled in correctly?',
      SUBMIT_CHANGES: 'Submit',
      CANCEL: 'Cancel',
      SUCCESS_SAVE: 'Billing data updated successfully',
      COST_PER_MIN: 'Price per minute:',
      TOTAL_MINUTES: 'Total time:',
      TIME_USAGE: 'Time of usage',
      TOTAL_NUMBER: 'Training sessions:',
      PAYMENT_METHOD: 'Payment method',
      PRICE_PER_SESSION: 'Price per training session: 0,00 € incl. 19% VAT',
      TEST_FREE: 'Test sessions are always free of charge.',
      TRAINING_STATUS: 'Status:',
      PAID: 'Payed',
      NOT_PAID: 'Not Payed',
      GOCARDLESS_TEXT: 'Direct debit (Gocardless)'
    },
    BILLING_MONTHS: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December:'December',
    },
    DELETE_USER: {
      HEADING: 'Are you sure want to delete your account and all user data?',
      CONFIRM: 'Confirm',
      CLOSE: 'Abort',
      YES: 'Yes',
      NO: 'No'
    },
    COACHMARKS_BUTTONS: {
      NEXT: 'Next',
      PREV: 'Previous',
      END: 'End',
      SHOW_AGAIN: 'Show again?'
    },
    SUPERVISOR_PROFILE_COACHMARKS : {
      MENU_ARROW: 'Make the menu narrower or wider',
      SIDE_MENU: 'This is the side menu of the app. You find all screens here. The workflow is from top to bottom. You are now on Profile page.',
      PROFILE_DETAILS: 'This is the Profile screen. You can see and edit your profile here, manage your Supervisors and Users. See the groups and have a look at your Billing and News section. It is also possible to establish a direct or group video call with another online Supervisor or regular user in a separate browser window. Very convenient – have a webcam and headset ready.',
      SIMULATOR_MODE_MODE: 'If you want to test the app, without a connected eSense EEGenius, you can turn on/off the Simulator mode here. It generates random signals in all screens of the side menu, beside “Raw Signal”.',
      INFO_BUTTON: 'Hit this button to learn about our recommended electrode placement. See the Youtube video for a detailed explaining of “How to setup the cap and place the electrodes”.',
      CONNECT_BUTTON: 'If you are ready with correctly placed electrodes, turn on your eSense EEGenius, wait until the blue light is solid, so the device is able to connect. Then hit this “Connect” button.',
      LANGUAGE_SELECT: 'Switch your preferred language of the app here',
      SIGN_OUT: 'Access your Profile here and sign out.'
    },
    SUPERVISOR_AMPLITUDE_COACHMARKS: {
      SHOW_HIDE_TEXT: 'Click each frequency band button to show/hide the curve in the oscilloscope',
      CHANNEL_TEXT: 'Choose the Channel 1 or 2 of the eSense EEGenius. Currently, only Channel 1 is available.',
      Y_AXIS_TEXT: 'Set the Y-axis maximum value in µV',
      SECONDS_TEXT: 'Set the X-axis maximum length in seconds.',
      AVERAGE_TEXT: 'Set the averaging of the signals in seconds.',
      AVERAGE_PRESETS: 'Choose from some average presets in seconds to apply to the signal.',
      ZOOM_STEP_TEXT:'Set the zoom step of the Y-axis when using the buttons on the right to the oscilloscope.',
      CENTER_CHART_TEXT: 'This is the Amplitude Overview screen. You can have a look at 10 pre defined frequency bands of your EEG data in real time. Sampling is done internally with 2000 Hz and averaged in the device to 25 Samples per seconds for each frequency band and transmitted via Bluetooth® and drawn here.',
      ZOOM_BUTTONS: 'Zoom in and out with the Y-axis with these buttons.',
      RECORD_ACTIVITY: 'If you want to record your activity from this screen to the Analysis section, you can choose a preset length and hit the red record button to start recording. It will automatically stop after the preset length or when you manually hit the stop button at any time. For best comparison in analysis, recordings of the same length are highly recommended.'
    },
    SUPERVISOR_RAW_COACHMARKS: {
      CHANNEL_TEXT: 'Choose the Channel 1 or 2 of the eSense EEGenius. Currently, only Channel 1 is available.',
      Y_AXIS_TEXT: 'Set the Y-axis maximum value in µV',
      SECONDS_TEXT: 'Set the X-axis maximum length in seconds.',
      ZOOM_STEP_TEXT:'Set the zoom step of the Y-axis when using the buttons on the right to the oscilloscope.',
      ZOOM_BUTTONS: 'Zoom in and out with the Y-axis with these buttons.',
      CENTER_CHART_TEXT: 'This is the Raw Signal screen. You can have a look at your raw signal of EEG data in real time. Sampling is done with 250 Hz and transmitted via Bluetooth® to be drawn here.'
    },
    SUPERVISOR_RATIO_COACHMARKS: {
      SHOW_HIDE_TEXT: 'Click each frequency band button to show/hide the curve in the oscilloscope',
      CHANNEL_TEXT: 'Choose the Channel 1 or 2 of the eSense EEGenius. Currently, only Channel 1 is available.',
      Y_AXIS_TEXT: 'Set the Y-axis maximum value in µV',
      SECONDS_TEXT: 'Set the X-axis maximum length in seconds.',
      AVERAGE_TEXT: 'Set the averaging of the signals in seconds.',
      AVERAGE_PRESETS: 'Choose from some average presets in seconds to apply to the signal.',
      ZOOM_STEP_TEXT:'Set the zoom step of the Y-axis when using the buttons on the right to the oscilloscope.',
      CENTER_CHART_TEXT: 'This is the Ratio Overview screen. You can have a look at 3 free to choose ratios of frequency bands of your EEG data in real time. Sampling is done internally with 2000 Hz and averaged in the device to 25 Samples per seconds for each frequency band and transmitted via Bluetooth® and drawn here.',
      ZOOM_BUTTONS: 'Zoom in and out with the Y-axis with these buttons.',
      GLOBAL_AVERAGES_TEXT: 'I addition to the three chosen ratios, you find some often used ratios calculated here in numbers (based on a 60s average).'
    },
    SUPERVISOR_TEST_CONFIG_COACHMARKS: {
      PARTS_TEXT: 'Select up to 10 parts within a single test template.',
      LENGTH_TEXT: 'Define the length of each part individually.',
      CUSTOM_TEXTS: 'Add custom texts',
      EACH_PART_TEXT: 'Choose a text for each part.',
      SOUND_TEXT: 'Choose an optional sound file or hit the checkbox to play a tone at the end of a part.',
      IMAGE_TEXT: 'Add any optional image e.g. with instructions or just a relaxing background',
      TONE_TEXT: 'Test the tone that is played at the end of a part with active checkbox.',
      CREATE_TEXT: 'Assign a user to your created test configuration and save it under some name. You can then also start the test to try it yourself.',
      PAGE_TEXT: 'This is the Test configuration screen. As a supervisor, you can define individual test situations for your users. You can define up to 10 parts of custom length with a text, sound and/or image shown. You save and assign tests to specific users and they can run them automatically. In the Analysis section, a detailed review of the tests is possible.'
    },
    SUPERVISOR_TEST_SCREEN_COACHMARKS: {
      PAGE_TEXT: 'This is the Test screen. Here you can choose and run a test that you have created in Test Configuration screen or that has been assigned to you by another supervisor. Choose the correct channel which you are using the eSense EEGenius with (normally Channel 1) and connect your device.\n'
    },
    SUPERVISOR_TRAINING_CONFIG_COACHMARKS: {
      PARTS_TEXT: 'Select up to 3 parts within a single training template.',
      TOPIC_TEXT: 'Choose of subtitle text for each part, that is later shown to the user while training.',
      LENGTH_TEXT: 'Select the length of each part',
      TRAINING_TYPE_TEXT: 'You can choose either ratio or amplitude training',
      BAND_TEXT: 'Select the frequency band(s) you want to train',
      THRESHOLD_TYPE_TEXT: 'Reward (increase) or Inhibit (decrease) the amplitude or ratio?',
      THRESHOLD_DIFFICULT: 'Choose the preset difficulty for each training part. The user can change it later during training.',
      YOUTUBE_TEXT: 'Choose a YouTube Playlist with feedback videos for the user or a custom video you can upload or chose from existing library.',
      FEEDBACK_TYPE: 'Define if the video should start/stop with feedback condition or play in normal or slow speed.',
      NOTES_TEXT: 'Add a note that is shown to the user for each part while the training is running.',
      VISIBILITY_TEXT: 'Make some elements of the training screen show or hidden by default. The user can change this setting during training.',
      CREATE_TEXT: 'Assign a user to your created training configuration and save it under some name. You can always assign the same training template to several users or also remove it again from a user.',
      PAGE_TEXT: 'This is the Training configuration screen. As a supervisor, you can define individual training situations for your users. You can define up to 5 parts of custom length with a topic, and your preferred training parameters. Add your individual YouTube playlist for custom feedback videos or choose the default ones. Add a note to show the purpose of this training to the user. You save and assign trainings to specific users and they can run them as often they want. In the Analysis section, a detailed review of the training sessions is possible.'
    },
    SUPERVISOR_TRAINING_PREPARE_COACHMARKS: {
      MAIN_TEXT: 'This is the Preset Training Screen. Here you can choose and select a training that you have created in Training Configuration screen or that has been assigned to you by another supervisor. Setup and connect your device as usual. When the signal quality is good, meaning good impedance and low (muscle) artefacts, you can select a test. Otherwise, please check and improve connection.'
    },
    SUPERVISOR_TRAINING_COACHMARKS: {
      HIDE_ELEMENT: 'Use the eye buttons to show / hide specific elements of the screen.',
      HIDE_OPTIONS: 'Show / Hide the options for live custom training settings for supervisors',
      YOUTUBE_TEXT: 'The  video starts/stops automatically if the threshold is fulfilled or not.',
      PAGE_TEXT: 'This is the Training screen. Here you can do a real time Neurofeedback training. As a supervisor, you can just start and record a custom training you can specify in any way. As a normal user you can run the trainings that have been assigned to you or choose some default protocols. In the Analysis section, a detailed review of the trainings is possible.',
      SIGNAL_TEXT: 'Here the signal (Amplitude or Ratio) is drawn as a line in real time.',
      SIGNAL_DISTANCE: 'Watch the distance of your current signal level to the set threshold in % and the success rate of positive feedback in % of the last minute. The distance should be around 20-30% (plus or minus, depending on training condition) and the success rate in between 60-90% for best results of learning.',
      ARTEFACT_TEXT: 'Muscle artefacts in the EEG signal are monitored and shown here. You can choose a threshold (2,4 or 6 µV) above the recording pauses dynamically, when artefacts are too high and above that limit.',
      RECORD_ACTIONS: 'If you want to record your activity from this screen to the Analysis section, you can choose a preset length and hit the red record button to start recording. It will automatically stop after the preset length or when you manually hit the stop button at any time. For best comparison in analysis, recordings of the same length are highly recommended.'
    },
	SUPERVISOR_RECORDINGS_COACHMARKS: {
      PARTS_TEXT: 'You need to select at least one recording for further analysis on the “Analysis Screen',
      ARCHIVE_TEXT: 'This is the list of all users that have assigned you as their supervisor. You can sort this list by clicking on the different headlines of the columns. You can search for a specific user in the search box field by Name, ID or E-Mail.',
      SELECT_USER: 'Select a user to analyse the archive of your clients. When no user is selected, you see your own recordings below.',
      SELECT_USER_GOALS: 'See the goals your users have defined in the survey in their profile for their Neurofeedback training.',
      NOTES_TEXT: 'Add or edit a note for each of your own recordings.',
      DELETE_TEXT: 'Delete the recordings of your own if needed.',
      DOWNLOAD_TEXT: 'Download each recording as CSV file, including all common amplitudes and ratios.',
      TOGGLE_TEXT: 'Toogle in between Trainings, Tests or Other, which are recordings from Amplitude, Ratio or Raw Signal Chart windows.',
      PAGE_TEXT: 'This is the list of all recordings that have been done for your user or other users that have assigned you as their supervisor. You can sort this list by clicking on the different headlines of the coloums. You can add/edit notes to each recording, download them as CSV files for further analysis in Excel or similar or delete them.'
    },
    SUPERVISOR_ANALYSIS_COAHMARKS: {
      Y_AXIS_TEXT: 'Set the Y-axis maximum value in µV',
      SECONDS_TEXT: 'Set the X-axis maximum length in seconds.',
      ANALYSIS_TYPE: 'Choose the type of Analysis',
      AVERAGE_TEXT: 'Set the averaging of the signals in seconds.',
      AVERAGE_PRESETS: 'Choose from some average presets in seconds to apply to the signal.',
      ZOOM_STEP_TEXT:'Set the zoom step of the Y-axis when using the buttons on the right to the oscilloscope.',
      PDF_INFO: 'Some info about the visible recording(s)',
      ZOOM_BUTTONS: 'Zoom in and out with the Y-axis with these buttons.',
      EXPORT_PDF_TEXT: 'Export the current view as PDF file.',
      CENTER_CHART_TEXT: 'This is the Analysis screen. Here you see the analysis of the recording(s) that you have selected before in “Select recording(s)” screen. You can choose from several types of analysis (line chart, boxplot of different types) and export the charts as PDF files to download.'
    },
    SUPERVISOR_DASHBOARD_COACHMARKS: {
      PROFILE_TEXT: 'The more you train, the more points you collect. One point per second of training!',
      EMAIL_TEXT: 'As a supervisor, you can also watch your clients dashboards and their progress.',
      ACTIVITY_TEXT: 'Watch your activity in this feed and turn on/off E-Mail notification about it.',
      TOTAL_TRAININGS_TEXT: 'The more you train, the more trophies you can earn. Reach the number of trainings shown to get them!',
      STATISTIC_TEXT: 'See you statistics for current day, week and month here and the best week of the month.',
      CALENDAR_TEXT: 'Each training and each test that has been done is added to this calendar and you earn a medal for it.',
      PROGRESS_TEXT: 'Track your progress of training and tests in this Dashboard. You can earn several rewards and reach certain levels. You see your activity in a history and your Neurofeedback training and testing activity in numbers and a calendar view. Keep on training regularly to reach the best results!'
    },
    SUPERVISOR_REMOTE_COACHMARKS: {
      CHOOSE_USER: 'Choose the user(s) you want to train with and hit “Submit” to add their charts below.',
      PAGE_TEXT: 'This is the Remote Training screen. A powerful real time Neurofeedback between a supervisor and up to 4 clients simultaneously. You can define a custom training protocol for each user and see the training parameters and progress in real time.',
      OPTIONS: 'With all these options you can define and modify in real time the training parameters of each or all users. This will change their training screen immediately. You have full remote control if your client has added and activated you as supervisor on their training screen.',
      THRESHOLD: 'Hit the “Auto Threshold” button for quick setting of a fitting threshold.',
      PARAMETERS: 'See the training parameters this user has here.',
      HIDE_ELEMENT: 'Switch between this Training view, the Amplitude or Ratio Overview and show/hide elements on the training screen of your client remotely with the eye buttons.',
      USER_CREDS: 'See the training parameters this user has here.',
      CHART_TEXT:'Watch the training signal, threshold, bar chart, artefacts and success parameters of your client in real time here.'
    },
    DEFAULT_TRAININGS: {
      RELAX: 'Relaxation Training',
      FOCUS: 'Focus & Concentration Training',
      SPEED_UP: 'Speed Up',
      SLOW_DOWN: 'Slow down'
    },
    DEFAULT_TESTS: {
      FOUR_MINUTES: 'Standard 4 Minutes Test'
    },
    DEFAULT_TRAINING_RELAX: {
      FIRST_SLIDE: 'This is a basic training of 10 minutes of Alpha training with the goal to increase the Alpha amplitude.',
    },
    DEFAULT_TRAINING_FOCUS: {
      FIRST_SLIDE: 'This is a basic training of SMR, LO-BETA amplitude to increase your Focus.',
      SECOND_SLIDE: 'This is a basic training of BETA 1 amplitude to increase your concentration.',
    },
    DEFAULT_TRAINING_SPEED: {
      FIRST_SLIDE: 'Speed up your Brain with this 10 minutes Training of decreasing the Ratio of THETA / BETA 1, that makes THETA less dominant and BETA 1 more prominent.'
    },
    DEFAULT_TRAININGS_SLOW: {
      FIRST_SLIDE: 'Slow down your Brain with more ALPHA than SMR, LO-BETA by increasing this Ratio.'
    },
    CUSTOM_VIDEO: {
      TYPE_HEADING: 'Custom Video Files',
      UPLOAD_VIDEO: 'Please upload your Video file:',
      VIDEO_TYPE: 'Visibility',
      VIDEO_PRIVATE: 'Private',
      VIDEO_PUBLIC: 'Public',
      DROP_TEXT: 'Please drop your video here or click to the area',
      YOUR_VIDEOS: 'Your videos:',
      PUBLIC_VIDEOS: 'Public videos:',
      UPLOADED_TEXT: 'Uploaded:',
      VIDEO_UPLOADING: 'Video is uploading...',
      SELECT_FROM_EXITING: 'Or select from exiting videos:',
      UPLOAD_VIDEO_HINT: 'Supported file formats: .mp4, .webm, .ogg Maximal file size: 100mb',
      FILE_LIMIT_WARNING: 'The size of the video file is larger than 100mb'
    },
    DELETE_CUSTOM_VIDEO: {
      CONFIRM_TEXT: 'Are you sure want to delete this video?'
    },
    VIDEO_HINT: {
      HEADING: 'Continue video from last position?',
      CONTINUE: 'Continue from last position',
      RESTART: 'Restart from beginning'
    },
    BAD_ARTEFAKT: {
      HEADING: 'Recording is paused because of bad electrode contact or too high signal artefacts. Electrode impedance must stay below 20 kOhm (see value at the top) and artefacts must stay below 6µV (see traffic light). Please improve electrode impedance or try to avoid movements and electrode/cable touching',
      ARTEFAKT_FIRST_PART: 'Signal artefacts = < 6µV',
      ARTEFAKT_SECOND_PART: 'Your current signal artefacts =',
      IMPEDANCE_FIRST_PART: 'Good impedance = 2-20 kOhm',
      IMPEDANCE_SECOND_PART: 'You current impedance ='
    },
    SURVEY: {
      MAIN_HEADING: 'My goals with Neurofeedback training are?',
      HINT_HEADING: 'Please select from the following topics to help us understand why you want to use Neurofeedback training with the eSense EEGenius.',
      SUB_HEADLINE: 'My goals/topics are (please select one or more options):',
      MAIN_TOPIC: 'Please choose main topic',
      SURVEY_AWARENESS: 'Awareness',
      AWARENESS_FIRST: 'I want a better concentration',
      AWARENESS_SECOND: 'I want a better and longer attention',
      AWARENESS_THIRD: 'I want to focus my brain better.',
      AWARENESS_FOUR: 'I want to speed up my inattentive brain',
      SURVEY_RELAX: 'Relaxation',
      RELAX_FIRST: 'Let me calm my mind',
      RELAX_SECOND: 'Help me to slow down my hyperactive brain',
      RELAX_THIRD: 'I want to train a meditative state',
      SURVEY_IMPROVE: 'Improvements',
      IMPROVE_FIRST: 'I want to improve my quality of sleep with a specific training',
      IMPROVE_SECOND: 'I want to improve my memory with a specific training',
      SURVEY_PREVENTION: 'Prevention',
      PREVENTION_FIRST: 'I want to use a specific training protocol for elderly people to prevent attention-related disorders or cognitive decline',
      PREVENTION_SECOND: 'I like to use Neurofeedback for promoting resilience and preventing the negative effects of chronic stress.',
      NEXT_STEP: 'Next step',
      FINISH: 'Save',
      BACK: 'Back',
      SURVEY_SAVED: 'Questionnaire data updated',
      SURVEY_EMPTY: 'Please complete the questionnaire',
      CHANGE_GOALS: 'You can always change your goals and do this questionnaire again.',
      SURVEY_HINT_TITLE: 'Software Update by 21st of July 2023',
      SURVEY_HINT_TEXT: 'We updated the eSense EEGenius Web App once again with more improvements and features. Due to this update, you may need to fill out the survey in your profile again to work with the app. We appoligize for that.'
    },
    USER_GOALS: {
      HEADING: 'Selected user goals',
      USER_TEXT: 'User',
      MAIN_GOAL: 'Main goal'
    },
    TRAINING_PREPARE: {
      HEADING: 'Have you setup and connected your eSense EEGenius?',
      SIGNAL_QUALITY: 'How is your signal quality?',
      SELECT_TRAINING: 'Please select your training:',
      SELECT_TEST: 'Please choose your test:',
      CONCENTRATION: 'Better concentration',
      IMPEDANCE: 'Impedance:',
      MUSCLE: '(Muscle) Artefacts:',
      CALM: 'Calm my mind',
      IMPROVE: 'Improve my memory',
      WARNING: 'Please connect your eSense and/or improve the signal quality, before your are able to choose your training.',
      FREE_SESSION: 'Test sessions are always free of charge.',
      TRAINING_MESSAGE: 'Price per training session: 0,00 €',
      TODO: 'Price per training session: 0,00 € incl. 19% VAT',
      TO_TRAINING: 'Go to training screen',
      CUSTOM_TRAINING: 'Your custom made training template(s) by your supervisor:',
      CUSTOM_TEST: 'Your custom made test template(s) by your supervisor:'
    },
    ELECTRODE_CONTACT_DIALOG: {
      TEXT_IMPEDANCE: 'To improve your electrodes impedance please check the electrode contact again. Are the electrodes in good place? Are they sitting tight enough with good contact? Are the small pads fully soaked with NaCl to have a complete connection between skin and electrode? Please re-arrange the electrodes, add some more NaCl if needed and then wait a few seconds for new impedance values to be calculated when electrodes are back in place.',
      TEXT_ARTEFAKT: 'Please do not move your head and do not tighten your muscles in the face like eybrows, ears, jaw. Stay still and your (muscle) artefacs will drop and stay at a small value below 6µV easily. This is important to maintain during the whole training. The EEG signal is a tiny and sensitive signal that can easiy be distorted by movements. Please relax and sit still during the whole measurement as best as you can.'
    },
    INVOICE: {
      DOWNLOAD: 'Download Invoice'
    },
    USER_FILLING: {
      HEADING: 'Welcome to the eSense EEGenius Web App',
      SUBHEADING: 'Please follow these simple steps to get started:',
      FIRST_STEP: 'Fill in the questionnaire on this profile page, so we learn about your goals with Neurofeedback training.',
      SECOND_STEP: 'Complete your personal details in the invoice tab of the profile.',
      THIRD_STEP: 'Place the electrodes and connect your eSense EEGenius with the help of the respective buttons in the upper right bar.',
      DONT_SHOW_AGAIN: 'I understand, do not show this again.',
      NEXT_STEP: 'Next step',
      PREVIOUS_STEP: 'Previous step',
      CLOSE: 'Close'
    },
    APPROVAL_DIALOG: {
      LIST: 'List for users, waiting for approval',
      DATE: 'Request date',
      STATUS: 'Status',
      ACTION: 'Action',
      PENDING: 'Pending',
      USER_ACCEPTED: 'Accept this user',
      USER_DECLINED: 'Decline this user',
      CLOSE: 'Close'
    },
    ACTIVITY_FEED: {
      REMOVED_SUPERVISOR: 'You removed a supervisor',
      ADDED_SUPERVISOR: 'You added a supervisor',
      ACCEPTED_USER: 'You accepted a user',
      DECLINED_USER: 'You declined a user'
    },
    TRAINING_SCREEN: {
      THRESHOLD_CHANGED: 'Threshold now works in manual mode!',
      THRESHOLD_AUTO: 'The threshold algorithm is optimized and works in automatic mode!',
      SHORT_SESSION: 'This session has been stopped before the regular end. It is shorter than 3 minutes and this makes a useful analysis impossible. It will not be saved. Please start again and fully complete a training.',
      SAVING_SESSION: 'Your session is saving...',
      SENSOR_DISCONNECT: 'Your Bluetooth connection with the eSense EEGenius has been completly lost. We try to automatically stop and save your current running test or training session, if there is any. Please make sure to turn off all other bluetooth devices beside the eSense EEGenius for a stable connection. Check your bluetooth driver and the battery status of the eSense EEGenius. Then try to reconnect and start again.'
    },
    SUPERVISORS_TREE: {
      SEARCH: 'Search',
      SORTING: 'Sort by',
      BY_NAME: 'Sort by Name',
      BY_LAST_LOGIN: 'Sort by Last Login Date',
      BY_NUMBER: 'Sort by Number of Users',
      LAST_LOGIN: 'Last Login:',
      SUPERVISOR_ARCHIVE: `Supervisor's Archive`,
      TOTAL_TRAININGS: 'Total trainings:',
      USER_ARCHIVE: 'User Archive',
      ALLOWED_AS_SUPERVISOR: 'Users of this Supervisor:',
      HIDDEN_SORT: 'Hide hidden supervisors'
    },
    SUPERVISOR_ACTIVITY: {
      SEND_EMAIL: 'Send reminder email',
      MONTHS_AGO: 'months ago',
      EMAIL_SENT: 'Email reminder sent successfully',
      REMIND_SENT: 'Remind sent:',
      EEG_USER: '(EEGenius user)',
      CHANGE_ROLE: 'Change role',
      CONFIRM_TEXT: 'Are you sure wants to change role to normal for this supervisor?',
      DELETE_DATA: 'Delete all data',
      DELETE_DATA_TEXT: 'Are you sure wants to delete all user data for this user?',
      DATA_DELETED: 'All user data deleted!'
    },
    PHONE_WARNING: {
      TEXT:  'We recognized that you are trying to use the eSense EEGenius on a smartphone. The screen size is too small for a propper handling of the eSense EEGenius App. Please use an Apple iPad®, iPad Air®, iPad mini® or iPad Pro® with iPadOS® with the eSense EEGenius iOS App or a powerful Android tablet with Google Chrome. Thank you!'
    },
    SAFARI_WARNING : {
      TEXT: 'We recognized that you are a Supervisor, trying to use the eSense EEGenius App on a mobile device. The screen size is too small for a propper handling of the eSense EEGenius Apps Supervisor functions. Only normal users can use a mobile device (iPad, Android Tablet) to attend a training. As a Supervisor, please use the eSense EEGenius App on a Windows, Mac or Linux computer running Google Chrome browser. Thank you!'
    },
    REMOVE_SUPERVISOR: {
      TEXT: 'Are you sure you want to remove this supervisor',
      FROM_LIST: 'from the list?',
      CONFIRM: 'Confirm',
      ABORT: 'Abort'
    },
    CHAT_WIDGET: {
      ACTIVE: 'Active',
      AWAY: 'Away',
      SEND: 'Send',
      MESSAGE: 'Message',
      SEARCH_TEXT: 'Find messages',
      SEARCH_MESSAGES: 'Messages:',
      SUPERVISOR_COMMENT: 'There is new comment:'
    },
    DELETE_USER_DIALOG: {
      CONFIRM_TEXT: 'Are you sure want to delete this user from your list ?'
    },
    BLUETOOTH_DEVICE_HINT: {
      TEXT: 'Attention: No other bluetooth device beside the eSense EEGenius should be paired with your PC/Laptop/iPad while using the eSense EEGenius. Please disconnect / turn off any other bluetooth devices while using the eSense EEGenius.'
    },
    SENSOR_STATS: {
      TOTAL_PACKETS: 'Total packets:',
      PACKETS_DROPPED: 'Packets dropped:',
      SYNC_ERROR: 'Sync error:',
      TOTAL_ERROR: 'Total errors:'
    }
  },
};
